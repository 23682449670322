@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".FeatureCampaign";

#{$selector} {
    &__container__content {
        padding: 0 1.5rem 1.5rem 1.5rem;
    }
}
