@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".FAQs";

#{$selector} {
    @include screenLargerThan($screen-xl) {
        max-width: 75%;
        margin: 0 auto;
    }

    &__title {
        padding-bottom: $space-md;
    }

    &__item {
        padding-top: $space-2xl;

        &:first-child {
            padding-top: 0;
        }
    }
}
