@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostHeaderSeperator";

#{$selector} {
    @include hidden();
    color: $light--gray;
    padding: 0 $space-md;

    &--dark {
        color: $dark--gray;
    }

    @include screenLargerThan($screen-md) {
        @include visible();
    }
}
