@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostFooter";

#{$selector} {
    padding-top: $space-xs;
    border-top: 2px solid $white;

    &--dark {
        border-color: $dark--lightGray;
    }

    &__meta {
        @include text("meta");
        display: flex;
        justify-content: space-between;
        color: $light--base;

        &--dark {
            color: $dark--base;
        }

        &__date {
            font-weight: 700;
            color: $light--gray;

            &--dark {
                color: $dark--gray;
            }
        }
    }
}
