@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Lottie";

#{$selector} {
    &__container {
        position: relative;
    }

    & > canvas {    
        position: relative;
        z-index: 1;
    }

    &__background {
        position: absolute;
        opacity: 0.2;
        z-index: 0;
        object-fit: contain;
        transform: scale(1.6);
        filter: invert(75%) sepia(74%) saturate(1555%) hue-rotate(337deg) brightness(102%) contrast(101%);

        &--secondary {
            filter: invert(56%) sepia(57%) saturate(614%) hue-rotate(84deg) brightness(101%) contrast(95%);
        }
    }
}
