@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".RichText";

#{$selector} {
    &__p {
        @include text("base");
        color: $light--base;

        &--dark {
            color: $dark--lightGray;
        }
    }

    &__pre {
        @include text("code");
        color: $light--base;
        padding: $space-lg $space-md;
        border: 1px solid $light--lightGray;
        border-radius: $ui-default-border-radius;
        background-color: $light--backgroundWhite;
        overflow-x: auto;
        overflow-wrap: break-word;

        &--dark {
            color: $dark--gray;
            background-color: $dark--backgroundWhite;
        }
    }

    &__ul,
    &__ol {
        padding: 0;
        list-style-type: none;
        color: $light--base;

        &--dark {
            color: $dark--base;
        }
    }

    &__li {
        display: flex;
        align-items: flex-start;

        &__icon {
            min-width: 20px;
            max-width: 20px;
            margin-right: $space-sm;
            margin-top: $space-xs;
        }
    }

    &__ol {
        &__decimal {
            @include text("meta");
            display: flex;
            justify-content: center;
            align-items: center;
            width: 19px;
            height: 19px;
            background-color: $light--primaryLight;
            color: $light--base;
            border: 1px solid $light--primary;
            border-radius: 200px;
            margin-right: $space-sm;

            &--dark {
                color: $dark--base;
                border: 1px solid $dark--primaryLight;
                background-color: $dark--primaryDark;
            }
        }

        li {
            display: -webkit-box;
        }
    }
}
