@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostTeaser";

#{$selector} {
    &__card {
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 0 !important;

        &__container {
            height: 100%;
            overflow: hidden;
        }

        &__content {
            padding: 0 $space-lg 0;
        }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: auto;
            padding: 0 $space-lg $space-lg $space-lg;
        }
    }

    &__meta {
        position: absolute;
        top: $space-lg;
        left: $space-lg;
        width: calc(100% - (2* $space-lg));
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;

        &__category {
            max-width: 50%;

            &__label {
                @include textLineClamp(1);
                display: inline-block;
            }
        }
    }

    &__image {
        object-fit: cover;

        &__wrapper {
            position: relative;
            width: 100%;
            height: 230px;
        }

        &__caption {
            bottom: $space-sm;
            text-align: right;
            padding-right: $space-sm;
            width: calc(100% - $space-sm);
        }
    }

    &__date {
        @include text("headline/xs");
        padding: 0;
        color: $light--gray;

        &--dark {
            color: $dark--gray;
        }
    }

    &__animation {
        width: 100%;
        height: 230px;
    }

    &__headline {
        @include textLineClamp(1);
        padding: $space-lg $space-lg 0;
    }

    &__content {
        @include text("base");
        @include textLineClamp(3);
    }

    &__link {
        display: flex;
        align-items: center;

        &__icon {
            margin-right: $space-xs;
        }
    }
}
