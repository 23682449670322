@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostHeaderReadCount";

#{$selector} {
    @include text("base");
    display: flex;
    align-items: center;
    padding-right: 0 $space-sm;
    color: $light--gray;

    &__icon {
        margin-right: $space-sm;
    }

    &--dark {
        color: $dark--gray;
    }
}
