@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".HeaderNavbar";

#{$selector} {    
    &__language__flag {
        margin-right: $space-md;
    }
}
