@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Loading";

#{$selector} {
    &__animation {
        &--size-xs {
            height: 40px;
            width: 40px;
        }

        &--size-sm {
            height: 60px;
            width: 60px;
        }

        &--size-md {
            height: 80px;
            width: 80px;
        }

        &--size-lg {
            height: 100px;
            width: 100px;
        }

        &--size-xl {
            height: 120px;
            width: 120px;
        }
    }
}
