@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Avatar";

#{$selector} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $space-sm;
    border-radius: 200px;
    overflow: hidden;

    &--light {
        background-color: $light--background;

        &--primary {
            background-color: $light--primary;
        }
    
        &--secondary {
            background-color: $light--secondary;
        }
    
        &--teritary {
            background-color: $light--teritary;
        }
    
        &--info {
            background-color: $light--gray;
        }
    
        &--success {
            background-color: $light--success;
        }
        
        &--warning {
            background-color: $light--warning;
        }
    
        &--error {
            background-color: $light--error;
        }
    
        &--white {
            background-color: $light--backgroundWhite;
        }
    }

    &--dark {
        background-color: $dark--background;

        &--primary {
            background-color: $dark--primary;
        }
    
        &--secondary {
            background-color: $dark--secondary;
        }
    
        &--teritary {
            background-color: $dark--teritary;
        }
    
        &--info {
            background-color: $dark--gray;
        }
    
        &--success {
            background-color: $dark--success;
        }
        
        &--warning {
            background-color: $dark--warning;
        }
    
        &--error {
            background-color: $dark--error;
        }
    
        &--white {
            background-color: $dark--backgroundWhite;
        }
    }

    &--has-size-xs {
        padding: $space-xs;
        width: 30px;
        height: 30px;
    }

    &--has-size-sm {
        width: 40px;
        height: 40px;
    }

    &--has-size-md {
        width: 60px;
        height: 60px;
    }

    &--has-size-lg {
        width: 80px;
        height: 80px;
    }

    &--has-size-xl {
        width: 100px;
        height: 100px;
    }

    &__image {
        object-fit: cover;
    }

    &__link {
        box-shadow: $elevation--card;
        border-radius: 200px;

        &--dark {
            box-shadow: $elevation--card--dark;
        }
    }
}
