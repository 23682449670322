@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostItem";

#{$selector} {
    &__grid {
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse !important;

        @include screenLargerThan($screen-lg) {
            flex-direction: row-reverse !important;
        }
    }

    &__headline {
        @include textLineClamp(2);
    }

    &__info {
        display: flex;
        align-items: center;
    }

    &__author {
        display: flex;
        align-items: center;
        padding-right: $space-xl;

        &__name {
            @include text("headline/xs");
            padding-left: $space-md;
            color: $light--base;

            &--dark {
                color: $dark--base;
            }
        }
    }

    &__date {
        @include text("headline/xs");
        color: $light--gray;

        &--dark {
            color: $dark--gray;
        }
    }

    &__read__count {
        @include text("base");
        padding-left: $space-md;
        color: $light--gray;

        &--dark {
            color: $dark--gray;
        }
    }

    &__content {
        @include text("base");
        display: flex;
        align-items: center;

        &__paragraph {
            @include textLineClamp(3);
            color: $light--base;
            
            &--dark {
                color: $dark--base;
            }
        }
    }

    &__image {
        min-height: 275px;
        object-fit: cover;

        &__wrapper {
            width: 100%;
            min-height: 275px;
            border-radius: $ui-background-border-radius;
            overflow: hidden;
        }

        &__content__wrapper {
            display: block;
        }
    }

    &__button {
        &__content {
            justify-content: flex-start;
        }
    }
}
