@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostItemAuthor";

#{$selector} {
    display: flex;
    align-items: center;
    padding-right: $space-xl;

    &__name {
        @include text("headline/xs");
        padding-left: $space-md;
        color: $light--base;

        &--dark {
            color: $dark--base;
        }
    }
}
