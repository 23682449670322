@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Image";

#{$selector} {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-bottom: $space-lg;

    &__caption {
        @include text("meta");
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
        left: 0;
        opacity: 0.5;
    }
}
