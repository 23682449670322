@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".DefaultLayout";

#{$selector} {
    &__skeleton {
        width: 100%;
        margin-bottom: $space-lg;
        border-radius: $ui-background-border-radius;
    }
    
    &__main {
        flex: 1;
    }
}
