@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Quote";

#{$selector} {
    margin: 0;
    &__quote {
        margin: $space-lg 0 $space-lg 0;
        padding: 0 $space-lg;
        border-left: 2px solid $light--lightGray;

        &__content {
            @include text("quote");
        }
    }

    &__caption {
        display: flex;

        &__avatar {
            margin-right: $space-lg;
        }

        &__author {
            display: flex;
            flex-direction: column;
            color: $light--black;

            &__name {
                @include text("headline/xs");
            }

            &__position {
                @include text("meta");
            }

            &--dark {
                color: $dark--black;
            }
        }
    }
}
