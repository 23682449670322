@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Accordion";

#{$selector} {
    &__content {
        border-top: 1px solid rgba($light--lightGray, 0.25);
    }
}
