@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PromoBanner";

#{$selector}

 {
    position: relative;
    padding: $space-2xl $space-xl;

    &__animation {
        @include centered-container();
    }

    &__card {
        margin-bottom: -($space-2xl * 3);

        &__content {
            display: flex;
            justify-content: center;
            padding: 0;
            color: $light--base;

            &--dark {
                color: $dark--base;
            }
        }
    }

    &__row {
        width: 100%;
        max-width: $screen-xl;
    }
    
    &__col {
        position: relative;
        padding: $space-3xl 0 !important;

        &:last-of-type {
            display: none;
            height: 400px;

            @include screenLargerThan($screen-md) {
                display: flex;
            }

            @include screenLargerThan($screen-lg) {
                height: 450px;
            }
        }

        @include screenSmallerThan($screen-xl) {
            padding: $space-3xl $space-xl !important;
        }
    }

    &__content {
        @include text('base');
        color: $light--base !important;

        &--dark {
            color: $dark--base !important;
        }
    }

    &__buttons {
        margin-top: $space-2xl;
    }

    &__image {
        height: 100% !important;
        object-fit: contain;
    }
}
