@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostHeader";

#{$selector} {
    &__headline {
        padding-top: $space-2xl;
    }

    &__meta {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $space-md 0;

        &__date {
            @include text("headline/xs");
            color: $light--gray;
            padding: 0 $space-sm;
    
            &--dark {
                color: $dark--gray;
            }
        }
    }
}
