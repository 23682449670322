@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ContentArea";

#{$selector} {
    &__header {
        padding-bottom: $space-lg;
    }

    &__image {
        margin-bottom: $space-2xl;
    }
}
