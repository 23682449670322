@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".PostHeaderAuthor";

#{$selector} {
    padding: 0 $space-sm;

    &__name {
        @include text("headline/xs");
    }
}
