@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Layout";

#{$selector}

 {
    height: 100%;

    &__body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
}
