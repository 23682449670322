@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Link";

#{$selector}

 {
  @include text("link");
  text-decoration: none;
  cursor: pointer;

  @include when-hovered() {
    text-decoration: underline;
  };

  &--light {
    color: $light--base;

    @include when-hovered() {
      color: $light--primary;
    }

    @include when-focused {
      color: $light--primary;
    }

    &--primary {
      color: $light--primary;
    }
  
    &--secondary {
      color: $light--secondary;
    }

    &--teritary {
      color: $light--teritary;
    }

    &--info {
      color: $light--gray;
    }

    &--success {
        color: $light--success;
    }
    
    &--warning {
        color: $light--warning;
    }

    &--error {
        color: $light--error;
    }

    &--white {
      color: $white;

      @include when-hovered() {
        color: $white;
      }
    }

    &--primary,
    &--secondary,
    &--teritary,
    &--success,
    &--error,
    &--info,
    &--warning {
      @include when-hovered() {
        color: $light--base;
      }

      @include when-focused {
        color: $light--base;
      }
    }
  }

  &--dark {
    color: $dark--base;

    @include when-hovered() {
      color: $dark--primary;
    }

    @include when-focused {
      color: $dark--primary;
    }

    &--primary {
      color: $dark--primary;
    }
  
    &--secondary {
      color: $dark--secondary;
    }

    &--teritary {
      color: $dark--teritary;
    }

    &--info {
      color: $dark--gray;
    }

    &--success {
        color: $dark--success;
    }
    
    &--warning {
        color: $dark--warning;
    }

    &--error {
        color: $dark--error;
    }

    &--white {
      color: $white;

      @include when-hovered() {
        color: $white;
      }
    }

    &--primary,
    &--secondary,
    &--teritary,
    &--success,
    &--error,
    &--info,
    &--warning {
      @include when-hovered() {
        color: $dark--base;
      }

      @include when-focused {
        color: $dark--base;
      }
    }
  }
}
