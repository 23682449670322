@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Table";

#{$selector} {
    &__caption {
        padding: $space-lg !important;
        color: $light--base !important;
        background-color: $light--backgroundWhite;

        &--dark {
            color: $dark--lightGray !important;
            background-color: $dark--backgroundWhite;
        }
    }
}
