@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/keyframes";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Button";

#{$selector}

 {
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    margin-right: 5px;

    &--icon-only {
      margin-right: 0;
    }
  }

  &__link {
    display: table;
    width: 100%;
    color: inherit;
    font-weight: 400;
    font-size: 14.7px;
    line-height: 1.7;

    &--is-not-outlined {
      padding: 9px $space-lg;
      font-size: 16px;
      font-weight: 600;
    }

    &--has-icon-only {
      display: contents;
    }

    @include when-hovered() {
      text-decoration: none;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    padding: 9px 15px;
    border-radius: 15px;
    width: 100%;

    &--icon-only {
      padding: 8px;
    }

    &--light {
      background-color: $light--backgroundWhite;
    }

    &--dark {
      background-color: $dark--backgroundWhite;
    }
  }

  &--has-fullWidth {
    width: 100% !important;
  }
}
